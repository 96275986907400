import { keyBy } from 'lodash'

import { UNKNOWN_STAT_KEY } from '~/constants'
import { VersionedGame } from '~/graphql/generated/schema'

// This library defined which games show up on the Games page and are available to be included in a workout
// The game slug must also be included in the backend shared/constants file gamesLibrary in order for game scores to be saved and stats to load.
// The area slug for each game is determined in that same backend gamesLibrary

// This library determines the following:
// assetsPath determines the location of the game file in AWS
// gamesPageImage and heroImage (the thumbnail and hero images) are located in the public/assets folder and the URL is coded here
// bestStatLabelKey is used to look up the translation key for the stat name, e.g. Level or Cards, the translations are in common.json under game.gameStats.statLabel
// isStatLabelFirst determines whether the stat label is placed before or after the stat number
// isEnglishOnly determines whether the game will show on the Games page and be available for workouts based off browser language
const GamesLibrary: Array<GameConfig> = [
  {
    slug: 'assist-ants',
    id: '779f5138-4e78-4f64-81b5-4ac685d9639a',
    gamesPageImage: '/assets/images/games/thumbnail/assist-ants.svg',
    heroImage: '/assets/images/games/hero/assist-ants.svg',
    version: '1.2.1',
    assetsPath: 'PaddleFighter_CC/web/1.2.1_2021_11_01/a23d00d9f84202dbc8f60015bf70530062f08d71/399548280/release',
    bestStatLabelKey: 'seeds-collected',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'brain-shift',
    id: 'de23e83f-59ec-4338-a727-f88331cdb819',
    gamesPageImage: '/assets/images/games/thumbnail/brain-shift.svg',
    heroImage: '/assets/images/games/hero/brain-shift.svg',
    version: '4.11.0',
    assetsPath: 'BrainShift/4.11.2/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
    localeAssets: {
      ja: {
        localeGamesPageImage: '/assets/images/games/thumbnail/brain-shift-ja.svg',
      },
      ko: {
        localeGamesPageImage: '/assets/images/games/thumbnail/brain-shift-ko.svg',
      },
    },
  },
  {
    slug: 'chalkboard-challenge',
    id: 'b098f5c2-49bd-4d7e-a433-ed927236c614',
    gamesPageImage: '/assets/images/games/thumbnail/chalkboard-challenge.svg',
    heroImage: '/assets/images/games/hero/chalkboard-challenge.svg',
    version: '3.0.4',
    assetsPath:
      'ChalkboardChallenge_CC/ChalkboardChallenge_CC/web/3.0.4_2021_04_28/94341450ccc6671b078ac19f7057170543b8296e/294023955/release',
    bestStatLabelKey: 'problems',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'cognition-kitchen',
    id: '59fa2cc4-1785-49ad-9f52-4e35077f9879',
    gamesPageImage: '/assets/images/games/thumbnail/cognition-kitchen.svg',
    heroImage: '/assets/images/games/hero/cognition-kitchen.svg',
    version: '4.11.6',
    assetsPath: 'CognitionKitchen/4.11.8/release',
    bestStatLabelKey: 'recipe-steps',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'color-match',
    id: '9902aecd-bc17-4562-8de7-808812cee3b3',
    gamesPageImage: '/assets/images/games/thumbnail/color-match.svg',
    heroImage: '/assets/images/games/hero/color-match.svg',
    version: '4.10.0',
    assetsPath: 'ColorMatch/4.10.2/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
    localeAssets: {
      fr: {
        localeGamesPageImage: '/assets/images/games/thumbnail/color-match-fr.svg',
      },
      pt: {
        localeGamesPageImage: '/assets/images/games/thumbnail/color-match-pt.svg',
      },
      ko: {
        localeGamesPageImage: '/assets/images/games/thumbnail/color-match-ko.svg',
      },
      ja: {
        localeGamesPageImage: '/assets/images/games/thumbnail/color-match-ja.svg',
      },
    },
  },
  {
    slug: 'contextual',
    id: '0eb7d1de-bffc-46da-8fcd-8583d25c7ec5',
    gamesPageImage: '/assets/images/games/thumbnail/contextual.svg',
    heroImage: '/assets/images/games/hero/contextual.svg',
    version: '2.2.1',
    assetsPath: 'Contextual_CC/web/2.2.1_2021_05_19/be10f53b114d1e694832b89007fd91990923f1c8/305804747/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'continuum',
    id: '3bad5358-e900-4ab0-b69d-c9f760e5c0b7',
    gamesPageImage: '/assets/images/games/thumbnail/continuum.svg',
    heroImage: '/assets/images/games/hero/continuum.svg',
    version: '3.1.2',
    assetsPath: 'Continuum_CC/web/3.1.2_2021_07_23/03a33aa38afb278216d28a3634bb9be3a9cbe7ed/341927954/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: true,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'disillusion',
    id: 'cb129d45-fac4-4fe5-bcd1-75d8b9ccc67d',
    gamesPageImage: '/assets/images/games/thumbnail/disillusion.svg',
    heroImage: '/assets/images/games/hero/disillusion.svg',
    version: '3.0.5',
    assetsPath: 'Disillusion_CC/web/3.0.5_2022_06_01/e6cc45072ab5e98e7431a0d3e365935781b9d612/553167559/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'eagle-eye',
    id: '93ba9128-2e52-43c9-aec8-5d4c1c2af287',
    gamesPageImage: '/assets/images/games/thumbnail/eagle-eye.svg',
    heroImage: '/assets/images/games/hero/eagle-eye.svg',
    version: '3.0.10',
    assetsPath: 'EagleEye_CC/web/3.0.10_2022_10_21/ad93df775168cd2073e22323ae2dec232fa68618/673123576/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    // TODO: use this once the game is updated with the correct metadata
    // bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'ebb-and-flow',
    id: 'e4250024-805d-43d3-93f0-9352b1621117',
    gamesPageImage: '/assets/images/games/thumbnail/ebb-and-flow.svg',
    heroImage: '/assets/images/games/hero/ebb-and-flow.svg',
    version: '4.9.8',
    assetsPath: 'EbbAndFlow/4.9.10/release',
    bestStatLabelKey: 'leaves',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'editors-choice',
    id: '6fc485e6-568c-439a-8db5-e341de3600c6',
    gamesPageImage: '/assets/images/games/thumbnail/editors-choice.svg',
    heroImage: '/assets/images/games/hero/editors-choice.svg',
    version: '3.0.4',
    assetsPath: 'EditorsChoice_CC/web/3.0.4_2022_10_12/aa805f16da52a2a9b88a36d1f20c628d4f309078/664637821/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'familiar-faces',
    id: '9c6e5307-3162-413f-acf7-c88e6f08522b',
    gamesPageImage: '/assets/images/default-game-card.svg',
    heroImage: '',
    version: '3.0.5',
    assetsPath: 'FamiliarFaces_CC/master/2d69537dc35ad0177496c9f81d92a71175cdde67/948384993/release',
    bestStatLabelKey: 'restaurant',
    isStatLabelFirst: true,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'feel-the-beat',
    id: 'd083bcd3-097b-42de-bdc8-cfb14a8f0eaa',
    gamesPageImage: '/assets/images/games/thumbnail/feel-the-beat.svg',
    heroImage: '/assets/images/games/hero/feel-the-beat.svg',
    version: '1.1.8',
    assetsPath: 'RhythmRage_CC/web/1.1.8_2023_07_27/ab0a0c43ee9568add92e36cd989f8521a638034d/947310532/release',
    bestStatLabelKey: 'beat-streak',
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'follow-that-frog',
    id: 'b19cc4d7-5c1f-4a87-b646-d3d8c9db97cb',
    gamesPageImage: '/assets/images/games/thumbnail/follow-that-frog.svg',
    heroImage: '/assets/images/games/hero/follow-that-frog.svg',
    version: '4.21.3',
    assetsPath: 'FollowThatFrog/4.21.7/release',
    bestStatLabelKey: 'jumps-ahead',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'fuse-clues',
    id: 'cc99d210-e71c-4028-a21a-309779329403',
    gamesPageImage: '/assets/images/games/thumbnail/fuse-clues.svg',
    heroImage: '/assets/images/games/hero/fuse-clues.svg',
    version: '3.1.0',
    assetsPath: 'FuseClues_CC/web/3.1.0_2022_06_08/c60197952945299ab47b6773e4d1ab943949d170/558691556/release',
    bestStatLabelKey: 'window',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'halve-your-cake',
    id: '74352ed0-9570-44d7-b5a9-2d565eda4172',
    gamesPageImage: '/assets/images/games/thumbnail/halve-your-cake.svg',
    heroImage: '/assets/images/games/hero/halve-your-cake.svg',
    version: '1.1.3',
    assetsPath: 'HalveYourCake_CC/web/1.1.3_2022_10_21/6bf063dbc8c7880b25668dedc1354b9a625c538d/673136127/release',
    bestStatLabelKey: 'dish',
    isStatLabelFirst: true,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'highway-hazards',
    id: '52e6f8e1-5585-49ca-b3e4-33c279463681',
    gamesPageImage: '/assets/images/games/thumbnail/highway-hazards.svg',
    heroImage: '/assets/images/games/hero/highway-hazards.svg',
    version: '3.0.2',
    assetsPath: 'HighwayHazards_CC/master/46219fb733761e48d96711389c51ebe54b4c1e78/182612889/release',
    bestStatLabelKey: 'mph',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'lost-in-migration',
    id: '11700bab-fbed-4d0c-8d05-42b696ec133e',
    gamesPageImage: '/assets/images/games/thumbnail/lost-in-migration.svg',
    heroImage: '/assets/images/games/hero/lost-in-migration.svg',
    version: '3.0.6',
    assetsPath: 'LostInMigration_CC/web/3.0.6_2022_06_01/32bb4a7b7ea0b9f5a145097e2904d5d3d5daf075/553166193/release',
    bestStatLabelKey: 'flocks',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'magic-chance',
    id: '520fabd8-01ea-45d5-8923-75a7203d7602',
    gamesPageImage: '/assets/images/games/thumbnail/magic-chance.svg',
    heroImage: '/assets/images/games/hero/magic-chance.svg',
    version: '1.1.1',
    assetsPath:
      'MagicChance_CC/MagicChance_CC/web/1.1.1_2021_05_13/cd143e14a58126c31c6dab45eba87a54a3e06d17/302168409/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'masterpiece',
    id: '99ee8966-a46f-4b54-a352-766c3189573b',
    gamesPageImage: '/assets/images/games/thumbnail/masterpiece.svg',
    heroImage: '/assets/images/games/hero/masterpiece.svg',
    version: '3.0.4',
    assetsPath: 'Masterpiece_CC/web/3.0.4_2023_07_27/705e098c773032d583e975be8e94653f9c22629a/947229149/release',
    bestStatLabelKey: 'pieces',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'memory-match',
    id: 'abe34230-e1b4-446e-8315-0b4e5cf8c3e9',
    gamesPageImage: '/assets/images/games/thumbnail/memory-match.svg',
    heroImage: '/assets/images/games/hero/memory-match.svg',
    version: '3.0.5',
    assetsPath: 'MemoryMatch_CC/web/3.0.5_2022_10_21/2524afc60bd37e09862f974b2d038d751c723421/673125911/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'memory-matrix',
    id: '3de8b1a9-1309-4a2c-97f5-be0af1aba8a4',
    gamesPageImage: '/assets/images/games/thumbnail/memory-matrix.svg',
    heroImage: '/assets/images/games/hero/memory-matrix.svg',
    version: '4.10.6',
    assetsPath: 'MemoryMatrix/4.10.8/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'memory-serves',
    id: 'c03300cb-1ed8-4e10-92b4-93d8f332ad98',
    gamesPageImage: '/assets/images/games/thumbnail/memory-serves.svg',
    heroImage: '/assets/images/games/hero/memory-serves.svg',
    version: '1.0.6',
    assetsPath: 'MemoryServes_CC/master/b4116104a1931d42e0fbae779f7802178038353a/948545780/release',
    bestStatLabelKey: 'deliveries',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'organic-order',
    id: '63d01274-12c4-4130-ae7b-5e43bed34cef',
    gamesPageImage: '/assets/images/games/thumbnail/organic-order.svg',
    heroImage: '/assets/images/games/hero/organic-order.svg',
    version: '3.0.5',
    assetsPath: 'OrganicOrder_CC/web/3.0.5_2022_10_05/95a2ae2e7a7997013fcbaf7b6cfee6e664e0906c/658563853/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'penguin-rally',
    id: 'e60d3c67-ae4d-44b7-b2b0-3cff5b5983d2',
    gamesPageImage: '/assets/images/games/thumbnail/penguin-rally.svg',
    heroImage: '/assets/images/games/hero/penguin-rally.svg',
    version: '4.8.6',
    assetsPath: 'PenguinRally/4.8.8/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'pet-detective',
    id: '6b3d672c-2b96-42a3-8893-9693f39f5c74',
    gamesPageImage: '/assets/images/games/thumbnail/pet-detective.svg',
    heroImage: '/assets/images/games/hero/pet-detective.svg',
    version: '3.1.7',
    assetsPath: 'PetDetective_CC/web/3.1.7_2022_05_25/029ca9f353f8867d3dce43ca9ba3302cefc292b0/547856056/release',
    bestStatLabelKey: 'pets-returned',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'pinball-recall',
    id: '3c59f4c5-cdc8-487b-a20b-99ae829829ff',
    gamesPageImage: '/assets/images/games/thumbnail/pinball-recall.svg',
    heroImage: '/assets/images/games/hero/pinball-recall.svg',
    version: '3.0.5',
    assetsPath: 'PinballRecall_CC/3.0.5_2023_04_12/5e1fec1bafcc6f465dda98c0be9f53ddc36ef83e/835144853/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'pirate-passage',
    id: '0bdcf7a3-2317-41dd-83e8-fa6163909bc8',
    gamesPageImage: '/assets/images/games/thumbnail/pirate-passage.svg',
    heroImage: '/assets/images/games/hero/pirate-passage.svg',
    version: '3.0.5',
    assetsPath: 'PiratePassage_CC/web/3.0.5_2022_13_07/17defbeac58a77e89b14e4a413056b0eac6274bc/587017483/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'playing-koi',
    id: 'da05d339-75ec-4cb9-a13b-89c00d096018',
    gamesPageImage: '/assets/images/games/thumbnail/playing-koi.svg',
    heroImage: '/assets/images/games/hero/playing-koi.svg',
    version: '3.0.4',
    assetsPath: 'PlayingKoi_CC/web/3.0.4_2021_09_15/ffd69bc704bba403576acd86f15c17ef4ea892ca/371167892/release',
    bestStatLabelKey: 'fish-fed',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'raindrops',
    id: '22d72145-6f5c-4f4c-9a30-61030d80e10b',
    gamesPageImage: '/assets/images/games/thumbnail/raindrops.svg',
    heroImage: '/assets/images/games/hero/raindrops.svg',
    version: '3.1.4',
    assetsPath: 'Raindrops_CC/web/3.1.4_2021_10_27/4af13177454056c0452a9474d89a961f7a3e3ce0/396650873/release',
    bestStatLabelKey: 'drops',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'river-ranger',
    id: '82e3b087-1d2c-469e-a9ad-eb317a30a8da',
    gamesPageImage: '/assets/images/games/thumbnail/river-ranger.svg',
    heroImage: '/assets/images/games/hero/river-ranger.svg',
    version: '3.1.5',
    assetsPath: 'RiverRanger_CC/web/3.1.5_2023_07_27/98ce104352b53e2ce097435bd1771d3dac175dbc/947231734/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'robot-factory',
    id: 'b53941ee-6c25-4b0a-9828-fd48f8eb159c',
    gamesPageImage: '/assets/images/games/thumbnail/robot-factory.svg',
    heroImage: '/assets/images/games/hero/robot-factory.svg',
    version: '3.0.5',
    assetsPath: 'RobotFactory_CC/web/3.0.5_2022_09_21/96f8d05a816e3beb614f8787e69f4713a74e975a/646687924/release',
    bestStatLabelKey: 'robots',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'ruled-out',
    id: '191b739c-fd78-4379-a3fb-f08cfc2f4a6b',
    gamesPageImage: '/assets/images/games/thumbnail/ruled-out.svg',
    heroImage: '/assets/images/games/hero/ruled-out.svg',
    version: '4.30.7',
    assetsPath: 'RuledOut/4.30.10/release',
    bestStatLabelKey: 'rules-discovered',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'skyrise',
    id: '1b252c4b-a603-4276-ac01-bfdf962bac6f',
    gamesPageImage: '/assets/images/games/thumbnail/skyrise.svg',
    heroImage: '/assets/images/games/hero/skyrise.svg',
    version: '1.3.0',
    assetsPath: 'ObservationTower_CC/web/1.3.0_2023_09_08/ab7d569025be60e9062fc4f022fbbd9ad3134e68/997884407/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'space-trace',
    id: '46c8cc4f-243b-46e9-8601-3877722c9c3d',
    gamesPageImage: '/assets/images/games/thumbnail/space-trace.svg',
    heroImage: '/assets/images/games/hero/space-trace.svg',
    version: '1.0.7',
    assetsPath: 'DesignFluency_CC/web/1.0.7_2021_08_11/7ad3eb669895135153f27436bf1c915e13d9d251/351628237/release',
    bestStatLabelKey: 'patterns',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'speed-match',
    id: '071c3e4c-962a-4d86-bf61-20a3cbccddc3',
    gamesPageImage: '/assets/images/games/thumbnail/speed-match.svg',
    heroImage: '/assets/images/games/hero/speed-match.svg',
    version: '4.12.0',
    assetsPath: 'SpeedMatch/4.12.2/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'speed-pack',
    id: 'ddfbbd8a-d832-4f04-b980-eec4e13cfc86',
    gamesPageImage: '/assets/images/games/thumbnail/speed-pack.svg',
    heroImage: '/assets/images/games/hero/speed-pack.svg',
    version: '3.0.6',
    assetsPath: 'SpeedPack_CC/web/3.0.6_2021_09_15/d78f3fe95f90e27e0328f1535a93162aade9755e/371167810/release',
    bestStatLabelKey: 'suitcases',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'splitting-seeds',
    id: 'a4962f2b-d102-474d-8cf6-8d384418b2d5',
    gamesPageImage: '/assets/images/games/thumbnail/splitting-seeds.svg',
    heroImage: '/assets/images/games/hero/splitting-seeds.svg',
    version: '3.0.4',
    assetsPath: 'SplittingSeeds_CC/master/2683f1e6129d6445d043880c9aa3a83c794bbbc8/275186282/release',
    bestStatLabelKey: 'seeds',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'star-search',
    id: 'a47fbba8-111e-4720-887f-2f9efe09fd05',
    gamesPageImage: '/assets/images/games/thumbnail/star-search.svg',
    heroImage: '/assets/images/games/hero/star-search.svg',
    version: '3.0.5',
    assetsPath: 'StarSearch_CC/web/3.0.5_2022_05_25/e6009b64a2c933240085e34624b50fecb861e381/547850780/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'taking-root',
    id: '1aa29910-7352-4fde-92a6-e134a3345e25',
    gamesPageImage: '/assets/images/games/thumbnail/taking-root.svg',
    heroImage: '/assets/images/games/hero/taking-root.svg',
    version: '3.0.7',
    assetsPath: 'TakingRoot_CC/web/3.0.7_2021_07_30/f2055f97c56b75f9571d6926274fcb7c13b76631/345567433/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: true,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'tidal-treasures',
    id: 'cfff978a-5bac-4d60-8fe6-4425e25be224',
    gamesPageImage: '/assets/images/games/thumbnail/tidal-treasures.svg',
    heroImage: '/assets/images/games/hero/tidal-treasures.svg',
    version: '3.0.4',
    assetsPath: 'TidalTreasures_CC/web/3.0.4_2021_09_22/865eeb3c1d8caaab17642a754f4300689bffd7fe/374982697/release',
    bestStatLabelKey: 'items',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'top-that',
    id: 'aeed5f5b-e4db-4693-abd9-b5ce2f3dd460',
    gamesPageImage: '/assets/images/games/thumbnail/top-that.svg',
    heroImage: '/assets/images/games/hero/top-that.svg',
    version: '1.1.3',
    assetsPath: 'TopThat_CC/web/1.1.3_2023_07_27/47c0d3d284e4b28f257530e5fa5eafa7821cf284/947282510/release',
    bestStatLabelKey: 'top-picks',
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'train-of-thought',
    id: '3564ab29-642f-4acc-9ee2-c7e3ac552d33',
    gamesPageImage: '/assets/images/games/thumbnail/train-of-thought.svg',
    heroImage: '/assets/images/games/hero/train-of-thought.svg',
    version: '4.9.7',
    assetsPath: 'TrainOfThought/4.9.10/release',
    bestStatLabelKey: 'trains',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'trouble-brewing',
    id: '17b5f376-5ac4-44e7-8e80-508201e62435',
    gamesPageImage: '/assets/images/games/thumbnail/trouble-brewing.svg',
    heroImage: '/assets/images/games/hero/trouble-brewing.svg',
    version: '3.1.3',
    assetsPath: 'TroubleBrewing_CC/web/3.1.3_2021_12_07/8cff16085e23d47a0f6a32a62ae7ffb96695a21e/424297560/release',
    bestStatLabelKey: 'orders',
    isStatLabelFirst: false,
    isEnglishOnly: false,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'word-bubbles',
    id: 'd0c8791c-f093-472b-9adf-fbf72f4e9db4',
    gamesPageImage: '/assets/images/games/thumbnail/word-bubbles.svg',
    heroImage: '/assets/images/games/hero/word-bubbles.svg',
    version: '3.0.6',
    assetsPath: 'WordBubbles3_CC/master/4c728b9c28ea1321c22c8b413afb581e296efeb1/208881242/release',
    bestStatLabelKey: 'words',
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'word-snatchers',
    id: '1ee24bfa-e2fa-4455-b69b-871e9eaaee32',
    gamesPageImage: '/assets/images/games/thumbnail/word-snatchers.svg',
    heroImage: '/assets/images/games/hero/word-snatchers.svg',
    version: '3.0.4',
    assetsPath: 'WordSnatchers_CC/master/00d31a7bcca5af078c295c4a1d010f5c6e3a78c6/208881198/release',
    bestStatLabelKey: 'letters',
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'whats-my-name',
    id: '5da6cb2e-c683-437a-98f4-cbc9c6433024',
    gamesPageImage: '/assets/images/games/thumbnail/whats-my-name.svg',
    heroImage: '/assets/images/games/hero/whats-my-name.svg',
    version: '4.19.7',
    assetsPath: 'WhatsMyName/4.19.9/release',
    bestStatLabelKey: 'names',
    isStatLabelFirst: false,
    isEnglishOnly: true,
    isEarlyAccess: false,
    isUnity: true,
  },
]

export const GamesLibraryDictionary = keyBy(GamesLibrary, 'slug') as Record<GameSlug, GameConfig>

export const allVersionedGames: VersionedGame[] = GamesLibrary.map((game) => ({
  gameSlug: game.slug,
  gameVersion: game.version,
}))

export default GamesLibrary
